@tailwind base;
@tailwind components;
@tailwind utilities;

.appContainer {
  text-align: center;
  display: grid;
  max-height: 100vh;
  grid-template-rows: 20fr 80fr;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  align-items: center;
  justify-content: center;
}

.splitContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.coordinatesContainer {
  display: grid;
  grid-template-columns: 84fr 16fr;
}

.aiChat {
}

/*
#195962
#F56F6C
#FFFFFF
#252932
#191C21
*/